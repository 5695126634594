import React from 'react'
import PropTypes from 'prop-types'

const { Fragment } = React

import { Hero } from 'react-bulma-components'

import Navbar from './navbar'
import Footer from './footer'

import './styles/base.scss'

class Layout extends React.Component {
  getWindow() {
    const windowGlobal = typeof window !== 'undefined' && window
    return windowGlobal
  }

  componentDidMount() {
    const window = this.getWindow()
    if (window) {
      window.decodeSupportURL = () => {
        const encoded = 'tvqqpsuAtojqqfs/bqq'
        let link = 'mailto:'
        for (let i = 0; i < encoded.length; i++)
          link += String.fromCharCode(encoded.charCodeAt(i) - 1)
        window.location.href = link
      }
    }
  }

  getHeader() {
    const {
      location: { pathname }
    } = this.props
    const navbarProps = {
      snipperLogo: this.props.snipperLogo,
      isNarrow: true
    }
    if (this.props.navbarIsFixed) {
      navbarProps.fixed = 'top'
      navbarProps.style = { backgroundColor: '#FFF' }
    }

    const menu = (
      <Hero.Head renderAs="header">
        <header className="nav">
          <div className="container">
            <Navbar pathname={pathname} {...navbarProps} />
          </div>
        </header>
      </Hero.Head>
    )
    return menu
  }

  render() {
    const {
      children,
      fullheight,
      bold,
      light,
      location: { pathname }
    } = this.props

    const heroProps = { fullheight, bold, light }

    return (
      <Fragment>
        <Hero {...heroProps}>
          {this.getHeader()}
          {children}
        </Hero>
        <Footer pathname={pathname} />
      </Fragment>
    )
  }
}

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
  fullheight: PropTypes.bool,
  bold: PropTypes.bool,
  light: PropTypes.bool,
  navbarIsFixed: PropTypes.bool,
  snipperLogo: PropTypes.object
}

Layout.defaultProps = {
  fullheight: true,
  bold: true,
  light: true,
  navbarIsFixed: true
}

export default Layout
