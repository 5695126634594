import Typography from 'typography'
import styleColors from './colors'
import presets from './presets'

const { colors } = styleColors

// const linkRaw = colors.link.substr(1)
// const linkHoverRaw = colors.linkHover.substr(1)

const options = {
  baseFontSize: `16px`,
  baseLineHeight: 1.6,
  headerColor: `${colors.black}`,
  bodyColor: `${colors.text}`,
  blockMarginBottom: 0.75,
  headerWeight: 800,
  headerFontFamily: [
    // `Spectral`,
    // `-apple-system`,
    // `BlinkMacSystemFont`,
    // `Segoe UI`,
    // `Roboto`,
    // `Oxygen`,
    // `Ubuntu`,
    // `Cantarell`,
    // `Fira Sans`,
    // `Droid Sans`,
    // `Helvetica Neue`,
    // `sans-serif`
  ],
  bodyFontFamily: [
    // `Spectral`,
    // `-apple-system`,
    // `BlinkMacSystemFont`,
    // `Segoe UI`,
    // `Roboto`,
    // `Oxygen`,
    // `Ubuntu`,
    // `Cantarell`,
    // `Fira Sans`,
    // `Droid Sans`,
    // `Helvetica Neue`,
    // `sans-serif`
  ],
  overrideStyles: ({ scale }) => {
    return {
      // Increase base font-size for phablet and desktop.
      [presets.Phablet]: {
        html: {
          fontSize: `${(16 / 16) * 100}%`
        },
        h1: {
          ...scale(7 / 5)
        }
      },
      [presets.Desktop]: {
        html: {
          // fontSize: `${(20 / 16) * 100}%`
        }
      }
    }
  }
}

const typography = new Typography(options)

export default typography

export const scale = typography.scale
export const rhythm = typography.rhythm
