import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title, titleTemplate }) {
  const template = data =>
    titleTemplate(data) || `%s | ${data.site.siteMetadata.name}`

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const metaKeywords =
          keywords.length > 0 ? keywords : data.site.siteMetadata.keywords
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={template(data)}
            meta={[
              {
                name: `description`,
                content: metaDescription
              },
              {
                property: `og:title`,
                content: title
              },
              {
                property: `og:description`,
                content: metaDescription
              },
              {
                property: `og:type`,
                content: `website`
              },
              {
                property: `og:url`,
                content: `https://snipperapp.com/`
              },
              {
                property: `og:site_name`,
                content: `snipper.app`
              },
              {
                property: `og:image`,
                content: `https://snipperapp.com/images/app-screenshot-2.png`
              },
              {
                name: `twitter:card`,
                content: `summary_large_image`
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author
              },
              {
                name: `twitter:title`,
                content: title
              },
              {
                name: `twitter:description`,
                content: metaDescription
              },
              {
                name: `twitter:image`,
                content: `https://snipperapp.com/images/app-screenshot-2.png`
              },
              {
                name: `google-site-verification`,
                content: `wNzBDdjypf7zOJA6htHT7fm1Homs3C6opwX4fy8Q4Rk`
              },
              {
                name: `author`,
                content: data.site.siteMetadata.author
              }
            ]
              .concat(
                metaKeywords.length > 0
                  ? {
                      name: `keywords`,
                      content: metaKeywords.join(`, `)
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  titleTemplate: () => {}
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  titleTemplate: PropTypes.func
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        name
        description
        author
        keywords
      }
    }
  }
`
