import React, { Component } from 'react'
import { Link as GatsbyLink } from 'gatsby'

import { Icon, Heading } from 'react-bulma-components'
import { Facebook, Twitter } from 'react-feather'
import cx from 'classnames'

import './styles.scss'

class Footer extends Component {
  state = {
    seoDescriptionOpened: false
  }

  toggleSeoContent(e) {
    e.preventDefault()
    this.setState({ seoDescriptionOpened: !this.state.seoDescriptionOpened })
  }

  render() {
    const { pathname } = this.props
    let seo = <></>
    let subscribe = <></>

    if (pathname === '/') {
      seo = (
        <div className="seo-text-wrapper">
          <div
            className={cx({
              'is-opened': this.state.seoDescriptionOpened,
              'seo-text-content': true,
              content: true,
              'is-small': true
            })}
          >
            <h2>Snippet manager mac: Good Code is a Road to Success</h2>
            <p>
              Manual typing of the same code parts every time you need it is a
              waste of time. Imagine that you have several projects that require
              entering the same data. Why should you do this each time if you
              can use the code snippet manager? Do yourself a favor. With this
              Mac Os program, you can use pre-made work samples in its original
              form, or by slightly adjusting the variables. Now you can organize
              your work, reduce time of adding the same type of information to
              each new project, and get a convenient programming environment.
            </p>
            <p>
              Of course, each project is unique and requires a proper approach.
              And this is correct because you expect that program will perform
              peculiar functions. But practice shows that eliminating the same
              type of repetitive actions can shorten your work time and help you
              save nerve cells. But they are not restored. Snipper app aims to
              solve the problem of processing, repeating the same part of the
              program, and provide a convenient working environment. And we talk
              about real benefits.
            </p>
            <h2>Code Snippet App: The New Way of Coding</h2>
            <p>
              Each Mac engineer knows that ready-made templates with snippets
              can greatly simplify work on a project, especially if action takes
              place in the Apple ecosystem. Mac Os snippets are the Grail in the
              world of programming. In order not to get confused in an infinite
              number of ready-made fragments, you need to have right place to
              keep data. Our snippet manager mac is here to help. So, let&apos;s
              see what every programmer can find here:
            </p>
            <ul>
              <li>modern interface with dark & light themes.</li>
              <li>really lightweight and comfortable GUI.</li>
              <li>syntax highlighting (you should like it).</li>
              <li>fully supported Markdown with the preview mode.</li>
            </ul>
            <p>
              This software was created by developers and for developers. This
              code snippet manager mac has a lot of advantages. Let&apos;s say
              it supports syntax highlighting for more than 120 programming
              languages. Not bad, right? It is very convenient during the
              preparation of large projects when every detail is essential.
              There is also an option to display invisible characters and line
              numbers. We know it&apos;s critical for those who want to control
              the workflow and avoid making mistakes in gists. It also
              implements Full Markdown and a preview mode. Working with such
              stuff requires a lot of time, so a convenient interface is
              fundamental.
            </p>
            <p>
              The program optimized for all latest versions of Mac OS, so you
              will not experience any difficulties at work. Every day we improve
              software so that you can get best experience and realize all your
              creative fantasies. Give all dirty work to us. This program is
              easy to cope with all your coding needs. Thanks to syntax
              highlighting, you can efficiently work on each software piece and
              create required number of templates for the following projects.
              Now you can easily integrate and all your best practices into new
              software systems from which you can automate most routine work.
            </p>
            <h2>Software to Create Proper Mac Snippets</h2>
            <p>
              Work with the gists should be comfortable. Otherwise, your
              favorite job will turn into torture. We have implemented dark and
              light themes so you can choose. The dark mode is available to fit
              you most and keep your performance at the top level. It will help
              you reduce the strain on your eyes. Also, we performed insertion
              of spaces instead of tabs. Not bad, right? We have implemented
              synchronization with GitHub Gist storage so that you can manage
              your projects and organize an ideal working environment. Save code
              snippets and share them with colleagues. Also, app supports
              multiple storage types. It is essential when you work with
              numerous sources.
            </p>
            <p>
              Also, you can easily store fragments of your GitHub gist
              exclusively on one device if privacy is important to you. You can
              switch between different projects, comment on the code, and add
              folders in local and cloud storage. The data catalog is easy to
              organize to find mac snippets in a few clicks. We have created a
              software interface that helps focus on coding and does not
              distract from workflow. All graphic options are available in the
              menu. You can customize tools for more comfortable work. The
              sections are intuitive, which is vital for everyday use.
            </p>
            <h2>The Reasons You Should Choose Snipper App</h2>
            <p>
              Let&apos;s be honest. Systematization and the ability to easily
              manage your gists are very important. Any engineer encoder needs
              to save ready-made templates and a convenient environment for
              work. Using this application, you can store code snippets, use
              iCloud to synchronize, and update on any gadget. With our program,
              you will get quick access to ready-made app parts types anywhere.
              We can offer you syntax highlighting for 120 programming
              languages. It is more than enough for effective work. Use your
              computer at work, at home, or view app parts in iCloud. Now you
              can be mobile and quickly access your gists anywhere.
            </p>
            <p>
              Work with GitHub app parts without an internet connection. The
              program also has advanced functionality and a set of hotkeys for
              automating work. Now every user can create a shortcut and use it
              for the quick app opening. Every Mac OS user wants a first-class
              experience, and we are ready to provide it. Here you will not
              encounter errors, work difficulties, or problems with
              synchronization. We have automated all processes, and they are
              reminiscent like a Swiss watch.
            </p>
            <h2>Say Yes To a Safety Snippet App for Mac</h2>
            <p>
              This app keeps everything impeccably organized. Organize your work
              and sync with GitHub and iCloud in one click. Search for both the
              title and content. Now each of your snippets is stored in a safe
              place. With this program, you can work efficiently. Create code
              and store it for quick access. All Mac elements are visually
              stylized for the Apple ecosystem, so your Mac will become a place
              to work with convenient software.
            </p>
            <p>
              Switch between shared and all gists. You can create app parts
              manually or import them from other files. This program designed so
              that you do not waste time on unnecessary actions. Focus on your
              work and leave the whole routine to us. This application is stable
              and offers the best user experience. Since the program was created
              for programmers, all the technical nuances are taken into account
              here. Make the right choice and automate your workflows.
            </p>
          </div>
          <div className="button-wrapper">
            <a href="#" onClick={this.toggleSeoContent.bind(this)}>
              {this.state.seoDescriptionOpened ? 'Hide ↑' : 'Read more ↓'}
            </a>
          </div>
        </div>
      )
      subscribe = (
        <div
          className="has-text-centered"
          style={{ marginTop: '2rem', marginBottom: '6rem' }}
        >
          <Heading size={1} renderAs="h2">
            Subscribe to SnipperApp News
          </Heading>
          <Heading subtitle size={5} renderAs="h5">
            The latest SnipperApp news, articles, and resources, sent straight
            to your inbox.
          </Heading>
          <div
            dangerouslySetInnerHTML={{
              __html: `
            <!-- Begin Mailchimp Signup Form -->
            <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css">
            <style type="text/css">
            #mc_embed_signup {
              width:100%;
            }
            .button {
              background-color: #735bd1;
            }
            /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
               We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
            </style>
            <div id="mc_embed_signup">
            <form action="https://teologov.us3.list-manage.com/subscribe/post?u=6ee6cf4308ac1c7e922faf98a&amp;id=f1fa6566b3" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">

            <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Your email address" required>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_6ee6cf4308ac1c7e922faf98a_f1fa6566b3" tabindex="-1" value=""></div>
              <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button" style="background-color: #735bd1; margin-left: .5rem;"></div>
              </div>
            </form>
            </div>

            <!--End mc_embed_signup-->
            `
            }}
          />
        </div>
      )
    }
    if (pathname === '/pricing') {
      seo = (
        <div className="seo-text-wrapper">
          <div
            className={cx({
              'is-opened': this.state.seoDescriptionOpened,
              'seo-text-content': true,
              content: true,
              'is-small': true
            })}
          >
            <p>
              SnipperApp 2 is a universal gist product for anyone who is
              involved in programming or wants to store snippets in one place.
              You can choose the free version as a guide. You can use local
              snippet storage to store your gists and search code. The free
              version includes the following features:
            </p>
            <ul>
              <li>Five unique tags search;</li>
              <li>SnipperApp 2 editor with all functions;</li>
              <li>Markdown & snippets filtering;</li>
              <li>The text search option;</li>
              <li>120+ languages support.</li>
            </ul>
            <p>
              This option is suitable for the local storage of snippets. But
              there is no GitHub Gist integration. Thus, your entire GitHub
              gists are available exclusively locally. There is also a tag limit
              here. Users of the free version cannot add gist snippets to
              favorites. In general, this is the best solution for beginning
              programmers and computer science students. Use this to daily
              markdown. Now you may not search the alternatives. Despite several
              limitations, it is still one of the best free code apps for
              personal use and fast file search. You can sort your gist
              snippets.
            </p>
            <p>
              Thanks to a quick search, you can identify all your snippets. The
              cool thing is that you can use markdown, local gist folders to
              search & edit projects: control invisible characters, markdown,
              and line numbers. Font customization and markdown help with
              everyday work. Thanks to this GitHub code application, you will
              reduce your routine gist activities and search for recent
              projects. Control your code syntax and snippets.
            </p>
            <h2>Monthly Subscription Benefits</h2>
            <p>
              The paid version of this online code snippet manager includes
              manually iCloud backup and full synchronization with GitHub code
              gists. You can use unlimited tags, backup, and add snippets to
              your favorites. Convenient import of code snippets simplifies
              daily work with program gists. It allows you to access your GitHub
              gists anywhere in the world and use markdown. Also, your GitHub
              code gists protected here. It’s the best solution among other
              apps, so you don’t have to search for alternatives.
            </p>
            <h2>Benefits of the Annual Subscription Plan</h2>
            <p>
              There’s no need to search for other apps. Now creating GitHub
              snippets can cost you 33% cheaper. By issuing an annual
              subscription to this snippet code manager, you get all the
              advantages of the GitHub gist functionality and save money. Here
              you can store essential code projects with markdown support,
              individual snippets, gist, and TODOs without restrictions.
            </p>
            <p>
              The design and functionality of the paid application developed,
              taking into account the best user experience. The sections with
              folders, GitHub storage, and tags are structured so that you get
              all your best code practices in one place. If you often work with
              code, then don’t pay for GitHub gist manager monthly. It is
              cheaper and has a great search algorithm. Use this paid manager
              and enjoy your work.
            </p>
            <h2>Reasons to Choose It</h2>
            <p>
              Any programmer, engineer, or computer science student needs a
              quality snippet manager to organize all working aspects and create
              gist under tight code deadlines. A long GitHub gist search and
              lack of standardization lead to the loss of money and customers.
              Using this cheap price snipper manager you can speed up your work,
              make backups and synchronize data with cloud storage. Create code
              templates, file search & use GitHub synchronization.
            </p>
          </div>
          <div className="button-wrapper">
            <a href="#" onClick={this.toggleSeoContent.bind(this)}>
              {this.state.seoDescriptionOpened ? 'Hide ↑' : 'Read more ↓'}
            </a>
          </div>
        </div>
      )
    }
    const Link = props => {
      const linkProps = { ...props }
      if (linkProps.to === pathname) {
        linkProps.rel = 'nofollow'
      }
      return <GatsbyLink {...linkProps} />
    }
    return (
      <footer className="footer">
        <div className="container">
          {subscribe}
          {seo}
          <div className="list-wrapper">
            <ul className="footer-list">
              <li>© 2023 Andrii Teologov 🇺🇦</li>
              <li>
                <Link
                  className="is-link is-outlined is-small"
                  to="/terms-of-service"
                >
                  Terms
                </Link>
              </li>
              <li>
                <Link
                  className="is-link is-outlined is-small"
                  to="/privacy-policy"
                >
                  Privacy
                </Link>
              </li>
            </ul>
            <ul className="footer-list">
              <li>
                <a
                  href="https://www.facebook.com/Snipper-App-454836605007272"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon>
                    <Facebook color="#959da5" />
                  </Icon>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/AppSnipper"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon>
                    <Twitter color="#959da5" />
                  </Icon>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
