import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Facebook, Twitter, Mail } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'gatsby-image'
import { Link as GatsbyLink } from 'gatsby'
import { Navbar, Icon, Container } from 'react-bulma-components'

import './styles.scss'
import '../../../utils/icons'

const isActive = (pathname = '', validPathnames = []) =>
  cx({ 'is-active': validPathnames.includes(pathname) })

class CustomNavbar extends Navbar {
  // override to not delete has-navbar-fixed-top class from <html /> (bug)
  componentWillUnmount() {}
}

class SnipperNavbar extends Component {
  state = {
    open: false
  }

  getWindow() {
    const windowGlobal = typeof window !== 'undefined' && window
    return windowGlobal
  }

  trackInitiateCheckout() {
    const window = this.getWindow()
    if (window) {
      window.fbq && window.fbq('track', 'InitiateCheckout')
      window.ga &&
        window.ga('send', 'event', {
          eventCategory: 'navbar-button',
          eventAction: 'klik',
          eventLabel: event.target.href,
          transport: 'beacon'
        })
    }
  }

  onAppStoreClick(event) {
    event.preventDefault()
    this.trackInitiateCheckout()
    setTimeout(() => {
      window.open(
        'https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12',
        '_blank'
      )
    }, 200)
  }

  render() {
    const { pathname, snipperLogo, isNarrow, ...navbarProps } = this.props
    const Link = props => {
      const linkProps = { ...props }
      if (linkProps.to === pathname) {
        linkProps.rel = 'nofollow'
      }
      return <GatsbyLink {...linkProps} />
    }

    const navbar = (
      <>
        <Navbar.Brand>
          <Navbar.Item
            renderAs="span"
            style={{ width: '2em' }}
            className="logo-wrapper"
          >
            <Link to="/">
              {snipperLogo ? (
                <Image fluid={snipperLogo} style={{ position: 'inherit' }} />
              ) : null}
            </Link>
          </Navbar.Item>
          <Navbar.Burger
            active={this.state.open}
            onClick={() => {
              this.setState({
                open: !this.state.open
              })
            }}
          />
        </Navbar.Brand>
        <Navbar.Menu active={this.state.open} className="centered">
          {/*it's a bit weird, but component doesn't have a really start position with padding
          // for menu items, so use "end" with a little hack*/}
          <Navbar.Container
            position="end"
            style={{ justifyContent: 'start', marginLeft: '1rem' }}
          >
            <Link
              to="/"
              className="title is-4"
              style={{
                marginBottom: 0,
                marginTop: '1.3rem',
                marginRight: '2rem',
                cursor: 'pointer'
              }}
              itemProp="name"
            >
              SnipperApp 2 🇺🇦
            </Link>
            <Link
              to="/"
              className={[isActive(pathname, ['/']), 'navbar-item'].join(' ')}
            >
              <span>Home</span>
            </Link>
            <Link
              to="/pricing"
              className={[
                isActive(pathname, ['/pricing', '/pricing/']),
                'navbar-item'
              ].join(' ')}
            >
              <span>Pricing</span>
            </Link>
            <Link
              to="/changelog"
              className={[
                isActive(pathname, ['/changelog', '/changelog/']),
                'navbar-item'
              ].join(' ')}
            >
              <span>Changelog</span>
            </Link>
            <Link
              to="/docs"
              className={[
                cx({ 'is-active': pathname.startsWith('/docs') }),
                'navbar-item'
              ].join(' ')}
            >
              <span>Docs</span>
            </Link>
            <Link
              to="/faq"
              className={[
                isActive(pathname, ['/faq', '/faq/']),
                'navbar-item'
              ].join(' ')}
            >
              <span>FAQ</span>
            </Link>
            <Link
              to="/blog"
              className={[
                cx({ 'is-active': pathname.startsWith('/blog') }),
                'navbar-item'
              ].join(' ')}
            >
              <span>Blog</span>
            </Link>
            <a
              className="navbar-item"
              href="https://www.dropbox.com/sh/9ijqtjpu92j5giv/AADK0Wk0nD3E6FnKEWuIRtjba?dl=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              Press Kit &rarr;
            </a>
            {/*<a
              href="https://www.producthunt.com/upcoming/snipperapp-2"
              className="navbar-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              SnipperApp 2
            </a>
            */}
          </Navbar.Container>
          <Navbar.Container position="end">
            <Navbar.Item
              href="https://twitter.com/AppSnipper"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-item tooltip is-tooltip-bottom"
              data-tooltip="@AppSnipper"
            >
              <Icon>
                <Twitter color="#727784" />
              </Icon>
            </Navbar.Item>
            <Navbar.Item
              href="https://www.facebook.com/Snipper-App-454836605007272"
              target="_blank"
              rel="noopener noreferrer"
              className="icon-item tooltip is-tooltip-bottom"
              data-tooltip="Facebook"
            >
              <Icon>
                <Facebook color="#727784" />
              </Icon>
            </Navbar.Item>
            <Navbar.Item
              href="javascript:decodeSupportURL()"
              className="tooltip is-tooltip-bottom"
              data-tooltip="Contact support"
            >
              <Icon>
                <Mail color="#727784" />
              </Icon>
            </Navbar.Item>
            <Navbar.Item className="download-button-nav">
              <a
                href="https://apps.apple.com/us/app/snipperapp-2/id1469182443?mt=12"
                className="button is-primary"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.onAppStoreClick.bind(this)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={['fab', 'apple']} />
                </span>
                <span>Download</span>
              </a>
            </Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      </>
    )

    return (
      <CustomNavbar
        active={this.state.open}
        className={cx({ 'snipper-navbar': true, 'is-wide': !isNarrow })}
        {...navbarProps}
      >
        {isNarrow ? <Container>{navbar}</Container> : <>{navbar}</>}
      </CustomNavbar>
    )
  }
}

SnipperNavbar.propTypes = {
  snipperLogo: PropTypes.object,
  isNarrow: PropTypes.bool
}

export default SnipperNavbar
