// configure font-awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTwitter,
  faFacebook,
  faApple
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

library.add(faTwitter, faEnvelope, faFacebook, faApple)
